import React, { useState, useEffect } from 'react'
// Primereact
import { Card } from 'primereact/card'
// Libraries
import Tippy from '@tippyjs/react'
import 'chartjs-adapter-moment'
// Components
import Table from './Table'
// Hooks
// import useSWR from 'swr'
import useAxios from 'axios-hooks'
import useToast from '../../hooks/useToast'
// Style
import './style.scss'
// Utilities
import numberWithCommas from '../../utilities/numberWithCommas'
// import { fetcher } from '../../utilities/network'

// Icons
import { BsCalendar3Event, BsCalendar3Week, BsCalendar3, BsHeartFill } from 'react-icons/bs'
import { AiOutlineNumber } from 'react-icons/ai'
import { useAuth } from '../../hooks/useAuth'
import { apiURL } from '../../utilities/network'
import axios from 'axios'

const OrgPlaylists = (props) => {
	const [totalFollowers, setTotalFollowers] = useState([]) // Total, day change, week change, month change
	const [seoData, setSeoData] = useState([])

	const { user } = useAuth()

	// Fetch playlist data
	const [{ data: playlists, error }] = useToast(useAxios(apiURL(`/playlists?org=${user.role}&sort=weekly`)), { loading: 'Loading playlists from database...' })
	// Fetch playlist tags
	const [{ data: tags }] = useAxios(apiURL(`/tags/?org_id=${user.role}`))

	useEffect(() => {
		if (!playlists) {
			return
		}
		// console.log(data)
		let tempTotal = 0; let tempDay = 0; let tempWeek = 0; let tempMonth = 0
		for (let i = 0; i < playlists.length; i++) {
			const playlist = playlists[i]
			tempTotal += playlist.data.f_spotify?.today.data || 0
			tempDay += playlist.data.f_spotify?.today.data - playlist.data.f_spotify?.yesterday.data || 0
			tempWeek += playlist.data.f_spotify?.today.data - playlist.data.f_spotify?.lastWeek.data || 0
			tempMonth += playlist.data.f_spotify?.today.data - playlist.data.f_spotify?.lastMonth.data || 0
			setTotalFollowers([tempTotal, tempDay, tempWeek, tempMonth])
		}
	}, [playlists])

	useEffect(() => {
		if (!tags) {
			return
		}
		// console.log(data)
		async function getSeoData () {
			const queries = []
			tags.forEach(tag => {
				queries.push(tag.tag)
				tag.queries.forEach(query => queries.push(query))
			})
			const seoDataRes = await axios.get(apiURL(`/seo/?queries=${queries.join(',')}`))
			if (seoDataRes?.data) {
				setSeoData(seoDataRes.data)
			}
		}

		getSeoData()
	}, [tags])

	if (error) {
		console.log(error)
		// return <p>{"Couldn't load from database. Please refresh the page to try again and/or contact Brandon."}</p>
	}

	return (
		<React.Fragment>
			<div className="card-row">
				<Card style={{ marginLeft: 0, marginRight: 0, marginTop: 12, marginBottom: 16 }}>
					<div className="glance-bar" style={{ display: 'flex', fontWeight: 'bold' }}>
						<p>At a glance:</p>
						<Tippy content="Total playlists"><span className="at-a-glance-stat"><AiOutlineNumber size={20} /> {playlists ? numberWithCommas(playlists.length) : '--'}</span></Tippy>
						<Tippy content="Total followers"><span className="at-a-glance-stat"><BsHeartFill size={18} /> {numberWithCommas(totalFollowers[0])}</span></Tippy>
						<Tippy content="1 day change"><span className="at-a-glance-stat"><BsCalendar3Event size={18} /> {totalFollowers[1] >= 0 ? '+' : '-'}{numberWithCommas(totalFollowers[1])}</span></Tippy>
						<Tippy content="1 week change"><span className="at-a-glance-stat"><BsCalendar3Week size={18} /> {totalFollowers[2] >= 0 ? '+' : '-'}{numberWithCommas(totalFollowers[2])}</span></Tippy>
						<Tippy content="1 month change"><span className="at-a-glance-stat"><BsCalendar3 size={18} /> {totalFollowers[3] >= 0 ? '+' : '-'}{numberWithCommas(totalFollowers[3])}</span></Tippy>
					</div>
				</Card>
			</div>
			<div style={{ flex: 1 }}>
				<Table playlists={playlists || []} tags={tags} user={user} error={error} seoData={seoData} />
			</div>
		</React.Fragment>

	)
}

export default OrgPlaylists
