import React, { useState, useRef, useEffect } from 'react'
// PrimeReact
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { AutoComplete } from 'primereact/autocomplete'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { TabView, TabPanel } from 'primereact/tabview'
import { Toast } from 'primereact/toast'
// Options
import { growthObjectiveOptions, maintenanceObjectiveOptions } from './options'
// Utilities
import { postIt, apiURL } from '../../utilities/network'
import dateString from '../../utilities/dateString'
// Hooks
import { useAuth } from '../../hooks/useAuth'
import useAxios from 'axios-hooks'
import axios from 'axios'

import './style.scss'
import { BsHeart, BsListOl } from 'react-icons/bs'

const ManualCampaignExpansion = ({ id, totalSpend, playlistData }) => {
	const [activeIndex, setActiveIndex] = useState(0)
	const [filteredPlaylists, setFilteredPlaylists] = useState(playlistData)
	const [campaignData, setCampaignData] = useState([])
	const toast = useRef(null)
	const { user } = useAuth()

	const [{ data, error }] = useAxios(apiURL(`/campaign?id=${id}`), { useCache: false })

	function addDisplayNameToPlaylistData (playlists) {
		const newPlaylists = []
		if (playlists) {
			for (let i = 0; i < playlists.length; i++) {
				const matchedData = playlistData.filter(obj => obj.id === playlists[i].id)
				if (matchedData.length > 0) {
					newPlaylists.push({ ...playlists[i], display_name: matchedData[0].display_name })
				}
			}
		}
		newPlaylists.push({ display_name: '' })
		return newPlaylists
	}

	useEffect(() => {
		console.log(data)
		if (!data || campaignData.length > 0) {
			return
		}

		// Add display name to campaign playlist data
		const newCampaignPlaylistDataData = { growth_playlists: [], maintenance_playlists: [] }
		newCampaignPlaylistDataData.growth_playlists = addDisplayNameToPlaylistData(data?.growth_playlists)
		newCampaignPlaylistDataData.maintenance_playlists = addDisplayNameToPlaylistData(data?.maintenance_playlists)

		setCampaignData({ ...data, ...newCampaignPlaylistDataData })
	}, [data])

	if (!data) {
		return <p>Loading...</p>
	}

	if (error) {
		console.log(error)
		return <p>{"Error fetching data. Please try again shortly - if it still doesn't work, talk to Brandon."}</p>
	}

	const searchPlaylists = (event) => {
		const query = event.query
		if (!playlistData) {
			setFilteredPlaylists([])
			return
		}
		const _filteredPlaylists = playlistData.filter((obj) => obj.display_name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
		setFilteredPlaylists(_filteredPlaylists)
	}

	async function saveChangesToDB (id) {
		console.log(id)
		// Get all the data
		toast.current.show({ severity: 'info', summary: 'Saving...', detail: 'Please wait...', life: 3000 })
		// Make sure first growth playlists are marked as active
		for (let i = 0; i < campaignData.growth_playlists.length; i++) {
			campaignData.growth_playlists[0].active = i < campaignData.active_growth_playlist_count
		}
		console.log(campaignData)
		const savingRes = await postIt('/campaign', { orgID: user?.role, id, newCampaignData: campaignData })
		if (savingRes.status === 200) {
			toast.current.show({ severity: 'success', summary: 'Saved changes to db', detail: 'Nice.', life: 3000 })
		} else {
			toast.current.show({ severity: 'error', summary: 'Issue saving changes to db', detail: 'Oh no.', life: 3000 })
		}
	}

	async function getInitValuesFromServer (playlistID) {
		const res = await axios.get(apiURL(`/getSinglePlaylistValues?playlist=${playlistID}`))
		console.log(res)
		return !res?.data ? {} : res.data
	}

	const PlaylistTable = (playlists, playlistsNameString) => {
		return (
			<DataTable
				value={playlists || []}
				reorderableRows
				reorderableColumns
				removableSort
				resizableColumns
				columnResizeMode="fit"
				scrollable
				scrollHeight="flex"
				onRowReorder={(e) => {
					const newCampaignData = { ...campaignData }
					newCampaignData[playlistsNameString] = e.value
					setCampaignData(newCampaignData)
				}}
				className="p-datatable-sm"
				emptyMessage={!data ? 'Loading...' : 'Done loading but nothing is here'}>
				<Column rowReorder style={{ maxWidth: 25 }} />
				<Column field="active" header="" style={{ maxWidth: 30, marginLeft: 0, marginRight: 0 }} body={rowData => rowData.active ? '🟢' : ''}></Column>
				<Column field={'id'} header={'Playlist'} body={(rowData, props) =>
					<div style={{ display: 'flex' }}>
						<span className="p-input-icon-left p-float-label" style={{ flex: 1, minWidth: 430 }}>
							<i className="ri-play-list-line"></i>
							<AutoComplete forceSelection
								value={playlists[props.rowIndex]} suggestions={filteredPlaylists}
								completeMethod={searchPlaylists} field="display_name"
								placeholder='Start typing...'
								onChange={(e) => {
									if (e.value === null) {
										return
									}
									const newPlaylists = [...playlists]
									const newCampaignData = { ...campaignData }
									if (typeof e.value === 'object') {
										newPlaylists[props.rowIndex] = { ...newPlaylists[props.rowIndex], display_name: e.value.display_name, id: e.value.id, name: e.value.name, prev_total_spend: totalSpend }
										if (playlists[props.rowIndex].id === '') {
											newPlaylists[props.rowIndex].added_date = new Date()
											newPlaylists[props.rowIndex].total_spend_when_added = totalSpend
										}
									} else {
										newPlaylists[props.rowIndex] = { ...newPlaylists[props.rowIndex], display_name: e.value, id: '' }
									}
									// if it's the last one, add a new null row
									newCampaignData[playlistsNameString] = props.rowIndex !== playlists.length - 1 ? newPlaylists : [...newPlaylists, { display_name: '' }]
									setCampaignData(newCampaignData)
								}} />
						</span>
						<Button
							type="button"
							className="p-button-text"
							icon={<i className="pi pi-external-link"></i>}
							style={{ marginLeft: 6, width: 25 }}
							onClick={() => {
								window.open(`https://open.spotify.com/playlist/${playlists[props.rowIndex].id}`, '_blank').focus()
							}}></Button>
					</div>
				} style={{ minWidth: 500 }} />
				<Column field="added_date" header="Added On" style={{ maxWidth: 150 }} body={rowData => {
					if (!rowData.added_date) return ''
					const theDate = new Date(rowData.added_date)
					return theDate ? dateString(theDate) : ''
				}}></Column>
				<Column field="objective" header="Objective" style={{ maxWidth: 280 }}
					body={(rowData, props) => <Dropdown value={playlists[props.rowIndex]?.objective || ''} options={playlistsNameString === 'growth_playlists' ? growthObjectiveOptions : maintenanceObjectiveOptions}
						onChange={async (e) => {
							const newCampaignData = { ...campaignData }
							newCampaignData[playlistsNameString][props.rowIndex].objective = e.value
							const initValues = await getInitValuesFromServer(rowData.id)
							newCampaignData[playlistsNameString][props.rowIndex].initial_value = e.value.includes('followers') ? initValues?.followers : e.value.includes('search') ? initValues?.seo : e.value.includes('date') ? initValues?.date : initValues.followers
							newCampaignData[playlistsNameString][props.rowIndex].followers_start = initValues?.followers
							newCampaignData[playlistsNameString][props.rowIndex].seo_start = initValues?.seo
							setCampaignData(newCampaignData)
						}
						} placeholder="None"
						style={{ position: 'relative', width: '100%' }}
					/>}
				></Column>
				<Column field="target" header="Target" style={{ maxWidth: 120 }}
					body={(rowData, props) => <InputText value={playlists[props.rowIndex]?.target || ''} onChange={(e) => {
						const newCampaignData = { ...campaignData }
						newCampaignData[playlistsNameString][props.rowIndex].target = e.target.value
						setCampaignData(newCampaignData)
					}} />
					}
				></Column>
				<Column field="initial_value" header="Started At" style={{ maxWidth: 100 }} body={rowData => rowData.initial_value || '0'}></Column>
				<Column field="followers_current" header="Followers" style={{ maxWidth: 100 }}></Column>
				<Column field="seo_current" header="SEO Rank" style={{ maxWidth: 100 }}></Column>
				<Column field="estimated_spend" header="Spend" style={{ maxWidth: 80 }} body={rowData => rowData?.estimated_spend ? Number(rowData.estimated_spend).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '-'}></Column>
				<Column field="beans" header="CPR" style={{ maxWidth: 80 }} body={rowData =>
					rowData?.estimated_spend && (rowData.current_value - rowData.initial_value) !== 0 ? Math.floor(rowData.estimated_spend / (rowData.current_value - rowData.initial_value) * 100) / 100 : '-'}></Column >
			</DataTable >
		)
	}

	return (
		<div style={{ display: 'flex', width: '100%' }}>
			<div style={{ flex: 1.5, width: '100%' }}>
				<div style={{ width: 'calc(100% - 40px)', marginLeft: 20, marginRight: 20 }}>
					<TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
						<TabPanel header="Growth Playlists">
							<div>
								{PlaylistTable(campaignData?.growth_playlists, 'growth_playlists')}
							</div>
							<div style={{ width: '100%', marginTop: 10, marginBottom: 10, paddingRight: 16, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
								<div>
									<Dropdown value={campaignData.active_growth_playlist_count || 1} options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(num => ({ label: num + ` playlist${num !== 1 ? 's' : ''}`, value: num }))}
										onChange={(e) => {
											const newCampaignData = { ...campaignData }
											newCampaignData.active_growth_playlist_count = parseInt(e.value)
											setCampaignData(newCampaignData)
										}}
										style={{ width: 130, position: 'relative', marginRight: 10 }}
									/>
								</div>
								<Button
									type="button"
									className="p-button"
									icon={<i className="pi pi-save"></i>}
									label="Save"
									style={{ width: 100 }}
									onClick={() => saveChangesToDB(id)}
								></Button>
							</div>
						</TabPanel>
						<TabPanel header="Maintenance Playlists">
							<div>
								{PlaylistTable(campaignData?.maintenance_playlists, 'maintenance_playlists')}
							</div>
							<div style={{ width: '100%', marginTop: 10, marginBottom: 10, paddingRight: 16, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
								<div>
									<Dropdown value={campaignData.active_maintenance_playlist_count || 1} options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(num => ({ label: num + ` playlist${num !== 1 ? 's' : ''}`, value: num }))}
										onChange={(e) => {
											const newCampaignData = { ...campaignData }
											newCampaignData.active_maintenance_playlist_count = parseInt(e.value)
											setCampaignData(newCampaignData)
										}}
										style={{ width: 130, position: 'relative', marginRight: 10 }}
									/>
								</div>
								<Button
									type="button"
									className="p-button"
									icon={<i className="pi pi-save"></i>}
									label="Save"
									style={{ width: 100 }}
									onClick={() => saveChangesToDB(id)}
								></Button>
							</div>
						</TabPanel>
						<TabPanel header="History">
							<DataTable
								value={campaignData?.playlist_history || []}
								removableSort
								resizableColumns
								columnResizeMode="fit"
								scrollable
								scrollHeight="flex"
								className="p-datatable-sm"
								sortField='removed_date'
								sortOrder={-1}
								emptyMessage={!data ? 'Loading...' : 'Done loading but nothing is here'}>
								<Column field="name" header="name" body={rowData => <a href={`https://open.spotify.com/playlist/${rowData.id}`} target='_blank' rel="noreferrer">{rowData.name}</a>}></Column>
								<Column field="added_date" header="Added"></Column>
								<Column field="removed_date" header="Removed"></Column>
								<Column field="objective" header="Objective"></Column>
								<Column field="target" header="Target"></Column>
								<Column field="followers_start" header={<span><BsHeart /> Start</span>} body={rowData => rowData.followers_start || '~0'}></Column>
								<Column field="followers_end" header={<span><BsHeart /> End</span>}></Column>
								<Column field="seo_start" header={<span><BsListOl /> Start</span>} body={rowData => rowData.seo_start || '~200'}></Column>
								<Column field="seo_end" header={<span><BsListOl /> End</span>}></Column>

								<Column field="estimated_spend" header="Approx Spend" body={rowData => <span>{'$'}{Math.round(rowData.estimated_spend * 100) / 100}</span>}></Column>
							</DataTable >
						</TabPanel>
					</TabView>
				</div >

			</div>
			<Toast ref={toast} />
		</div >
	)
}

export default React.memo(ManualCampaignExpansion)
