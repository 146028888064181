import React, { useEffect, useRef, useState } from 'react'

import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { Dropdown } from 'primereact/dropdown'
import { DataTable } from 'primereact/datatable'
import { InputNumber } from 'primereact/inputnumber'
import { Toast } from 'primereact/toast'

import { languageOptions } from '../OrgPlaylists/data'

import { apiURL, deleteIt, postIt, putIt } from '../../utilities/network'
import ArenaCampaignExpansion from './ArenaCampaignExpansion'
import ManualCampaignExpansion from './ManualCampaignExpansion'

import { useAuth } from '../../hooks/useAuth'
import useAxios from 'axios-hooks'
import useToast from '../../hooks/useToast'
import { InputText } from 'primereact/inputtext'

// import useSWR from 'swr'

const FeederManager = (props) => {
	const originalData = useRef(null)
	const [tableData, setTableData] = useState([])
	const [expandedRows, setExpandedRows] = useState(null)
	const toast = useRef(null)
	const dt = useRef(null)
	const debounceRefs = useRef({})

	const { user } = useAuth()

	const [{ data, error, loading }] = useToast(useAxios(apiURL(`/organization/feeders?org=${user.role}`)), {
		loading: 'Grabbing feeders...',
		success: 'Loaded Feeders.'
	})

	if (error) {
		console.log('Uh captain, we have a problemo')
	}

	useEffect(() => {
		console.log(data)
		if (!data || tableData.length !== 0) {
			return
		}

		if (originalData.current === null) {
			originalData.current = data.campaignData
		}
		setTableData(data.campaignData)
	}, [data])

	const header = (
		<div className="table-header">
			{user.role.toUpperCase()} Playlist Feeders
			<div>
				<div style={{ display: 'inline-block' }}>
					<Button label='New Feeder' icon={'pi pi-plus'} className="mr-2 p-button-sm p-button-outlined"
						onClick={async () => {
							console.log('New Feeder')
							const newCampaign = {
								campaign_name: 'New Feeder',
								growth_allocation: 100,
								strategy: 'manual'
							}
							const res = await postIt('/campaign', { orgID: user.role, newCampaignData: newCampaign })
							console.log(res)
							const newTableData = [...tableData]
							newTableData.push(res.data)
							setTableData(newTableData)
						}} />
				</div>
			</div>
		</div>
	)

	const updateCampaignProperty = async (id, field, value) => {
		try {
			// Update local state
			const newTableData = [...tableData]
			const index = newTableData.findIndex(feeder => feeder._id === id)
			newTableData[index][field] = value
			setTableData(newTableData)
			// Update server with debounce
			if (debounceRefs.current[field]) {
				clearTimeout(debounceRefs.current[field])
			}
			debounceRefs.current[field] = setTimeout(async () => {
				await putIt('/campaign', { id, field, value })
				debounceRefs.current[field] = null
				toast.current.show({ severity: 'success', summary: 'Success', detail: `${field} updated.`, life: 3000 })
			}, 500)
		} catch (err) {
			console.log(err)
			toast.current.show({ severity: 'error', summary: 'Error', detail: `Issue, couldn't set ${field}. Please try again.`, life: 3000 })
		}
	}

	return <React.Fragment>
		<div className="p-fluid">
			<div className="page-header">
				<h2>Feeder Manager</h2>
			</div>
			<p>Each link is <a href="https://hackertyper.com/">https://api.littlesymphonyrecords.com/redirect/ID/</a> (ex. https://api.littlesymphonyrecords.com/redirect/63868e00df3e3a53480b0995/)</p>
			<p>Click the copy button next to each link to get the redirect link</p>
			<br />
			<DataTable
				value={tableData}
				ref={dt}
				header={header}
				removableSort
				resizableColumns
				reorderableColumns
				columnResizeMode="fit"
				sortField={'campaign_name'}
				sortOrder={1}
				expandedRows={expandedRows}
				onRowToggle={(e) => {
					setExpandedRows(e.data)
				}}
				rowExpansionTemplate={rowData => rowData?.strategy === 'arena'
					? <ArenaCampaignExpansion id={rowData._id} playlistData={data?.playlistsInfo} />
					: <ManualCampaignExpansion id={rowData._id} total_spend={rowData.total_spend} playlistData={data?.playlistsInfo} />}
				scrollable
				scrollHeight="flex"
				className="p-datatable-sm"
				emptyMessage="No feeders. Make a new one!"
				loading={loading}
			>
				<Column expander style={{ maxWidth: 50 }} />
				<Column field={'campaign_name'} header={'Campaign'} sortable style={{ minWidth: 300 }} body={(rowData) =>
					<InputText value={rowData.campaign_name} onChange={async (e) => {
						await updateCampaignProperty(rowData._id, 'campaign_name', e.target.value)
					}} />
				} />
				{/* <Column field={'strategy'} header={'Strategy'}
					body={(rowData) => <Dropdown value={rowData?.strategy || 'manual'} options={strategyOptions} optionLabel="label" optionValue="value"
						onChange={async (e) => {
							await updateCampaignProperty(rowData._id, 'strategy', e.value)
						}
						} placeholder="None"
						style={{ position: 'relative', width: '100%' }}
					/>}
					style={{ minWidth: 100 }} /> */}
				<Column field="lang" header="Language" style={{ maxWidth: 260 }}
					body={(rowData) => <Dropdown value={rowData?.lang || ''} options={languageOptions} optionLabel="label" optionValue="value"
						onChange={async (e) => {
							await updateCampaignProperty(rowData._id, 'lang', e.value)
						}
						} placeholder="None"
						style={{ position: 'relative', width: '100%' }}
					/>}
				></Column>
				<Column field={'growth_allocation'} sortable header={'Growth %'}
					body={(rowData, props) => <InputNumber
						value={rowData?.growth_allocation ?? 100}
						onValueChange={async (e) => {
							await updateCampaignProperty(rowData._id, 'growth_allocation', e.value)
						}}
						suffix="%" />} style={{ maxWidth: 100 }} />
				<Column field={'url'} header={'URL'} body={rowData =>
					<Button
						type="button"
						icon={<i className="pi pi-external-link"></i>}
						style={{ marginLeft: 10, width: 45 }}
						onClick={() => {
							navigator.clipboard.writeText(`https://api.littlesymphonyrecords.com/redirect/${rowData?._id}/`)
							toast.current.show({ severity: 'success', summary: 'Success', detail: 'Copied campaign redirect link to clipboard.', life: 3000 })
						}}>
					</Button>
				} style={{ maxWidth: 80 }}
				/>
				{/* Delete button column */}
				<Column body={(rowData) => <Button severity='danger' icon={'pi pi-trash'} className="p-button-sm p-button-outlined"
					onClick={async () => {
						console.log('Delete Feeder')
						const res = await deleteIt(`/campaign?id=${rowData._id}&orgID=${user.role}`)
						console.log(res)
						const newTableData = tableData.filter(feeder => feeder._id !== rowData._id)
						setTableData(newTableData)
					}} />
				} style={{ maxWidth: 100 }} />
			</DataTable>
		</div >
		<Toast ref={toast} />
	</React.Fragment >
}

export default FeederManager
